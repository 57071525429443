declare var dataLayer: any;

const joinForms = document.querySelectorAll<HTMLFormElement>(
  "form[data-join-step]"
);

const getVisitorType = () =>{
  
  return window.Westfund.IsOverseas ? "Overseas" : "Domestic";

};

joinForms.forEach((joinForm)=>{

  const getSelectOptionText = (name: string): string => {
    const formData = new FormData(joinForm);
    const selectValue = formData.get(name);
    const selectElement = joinForm.querySelector(`[name="${name}"]`) as HTMLSelectElement;
    const selectedOption =  selectElement.querySelector(`option[value="${selectValue}"]`);
    const isHidden = selectElement.closest('.hidden');
  
    return (isHidden)
      ? ''
      : (selectedOption?.innerHTML || '').trim();
  }  
 
  
  if (joinForm !== null) {
    const step = joinForm.getAttribute("data-join-step");
  
    // Analytics spreadsheet row no. 2
    if (step === "residency") {
  
      joinForm.addEventListener("submit", () => {
        
        const selectedResidencyElem = document.querySelector( 'input[name="Residency"]:checked');
  
        dataLayer.push({
          event: "quoteStep1",
          step_number: 'Step 1',
          residencyStatus: selectedResidencyElem.dataset.analyticsValue,
        });
  
      }
      
      );
    }
  
    // Analytics spreadsheet row no. 3
    if (step === "what-cover") {
      joinForm.addEventListener("submit", () => {
        const formData = new FormData(joinForm);
        const coverType = formData.get("Cover") || "";
  
        dataLayer.push({
          event: "quoteStep2",
          coverSelection: (coverType === 'hospital-extras')
            ? "Hospital & Extras"
            : "Ambulance Cover",
        })
      });
  /*
      document.getElementById("go-to-ambulance").addEventListener("click", () =>
        dataLayer.push({
          event: "quoteStep2",
          coverSelection: "Ambulance Cover",
        })
      );
  */    
    }
  
    if (step == "overseas-purpose") {
      joinForm.addEventListener("submit", () => {
        dataLayer.push({
          event: "quoteStep1.1",
          step_number: "Step 1.1",
          visitorType: getVisitorType()
        });
      });
    }

    if (step == "membership-status") {
      joinForm.addEventListener("submit", () => {

        if(window.Westfund.IsOverseas){

          dataLayer.push({
            event: "quoteStep2",
            step_number: "Step 2",
            visitorType: getVisitorType()
          });

        }
        else{

          dataLayer.push({
            event: "quoteStep1.1",
            visitorType: getVisitorType()
          });

        }
        
      });
    }
  
    // Analytics spreadsheet row no. 4 & no. 5
    if (step === "details" || step === "edit") {
      function calculateAge(dob) {
        var diff_ms = Date. now() - dob. getTime();
        var age_dt = new Date(diff_ms);
        return String(Math.abs(age_dt. getUTCFullYear() - 1970));
      }  

      joinForm.addEventListener("submit", () => {
        const formData = new FormData(joinForm);
  
        const dayElement = joinForm.querySelector<HTMLInputElement>('#MainDOBDay');
        const monthElement = joinForm.querySelector<HTMLSelectElement>('#MainDOBMonth');
        const yearElement = joinForm.querySelector<HTMLInputElement>('#MainDOBYear');
        const dob = new Date(parseInt(yearElement.value), parseInt(monthElement.value) - 1, parseInt(dayElement.value));
        const mainAge = calculateAge(dob);
        let partnerAge = '';
        const coverType = formData.get("CoverType") as string;

        if(coverType === "family" || coverType === "couple"){
          const partnerDayElement = joinForm.querySelector<HTMLInputElement>('#PartnerDOBDay');
          const partnerMonthElement = joinForm.querySelector<HTMLSelectElement>('#PartnerDOBMonth');
          const partnerYearElement = joinForm.querySelector<HTMLInputElement>('#PartnerDOBYear');
          const partnerDob = new Date(parseInt(partnerYearElement.value), parseInt(partnerMonthElement.value) - 1, parseInt(partnerDayElement.value));
          partnerAge = calculateAge(partnerDob);
        }


        const detailsData = {
          event: step === "details" ? "quoteStep3" : "coverDetailsUpdated",
          coverType: formData.get("CoverType") as string,
          state: formData.get("State") as string,         
          mainAge: mainAge,
          partnerAge: partnerAge,
          govtRebateTier: formData.get("GovtRebateTier") as string,
          pregnancyOnly:
            (formData.get("PregnancyOnly") as string) === "true"
              ? "true"
              : "false",
          visitorType: getVisitorType() 
        };
  
        if(window.Westfund.IsOverseas){
          const countryDropdown = joinForm.querySelector<HTMLSelectElement>("[name='CountryCode']");
          const selectedOption = countryDropdown.options[countryDropdown.selectedIndex]; 
          detailsData.originCountry = selectedOption.innerText;        
        }
  
        if(step === "details"){
          detailsData.step_number = "Step 3";
        }
        dataLayer.push(detailsData);
      });
    }
  
    switch(step)
    {
      case "tell-us-about-you":
        joinForm.addEventListener("submit", () => {
          const formData = new FormData(joinForm);
          const aboutYouData = {
            'event': 'aboutYouStep1',
            'referralChannel': getSelectOptionText('HeardAbout'),
            'visitorType': getVisitorType(),
            'flow_section': 'About You',
            'step_number': 'Step 1'
          };
          
          if(window.Westfund.IsOverseas){
            const countryDropdown = joinForm.querySelector<HTMLSelectElement>("[name='CountryCode']");
            const selectedOption = countryDropdown.options[countryDropdown.selectedIndex]; 
            aboutYouData.originCountry = selectedOption.innerText;           
          }
  
          dataLayer.push(aboutYouData);
        });
        break;
      case "tell-us-about-you-contact":
        joinForm.addEventListener("submit", () => {
          dataLayer.push({
            'event': 'aboutYouStep2',
            'visitorType': getVisitorType(),
            'flow_section': 'About You',
            'step_number': 'Step 2'
          });
        });
        break;
      case "tell-us-about-you-dependents":
        joinForm.addEventListener("submit", () => {         
          dataLayer.push({
            'event': 'aboutYouStep3',
            'flow_section':'About You',
            'step_number':'Step 3',
            'visitorType': getVisitorType()
          });
        });
        break;     
      case "your-cover-rebate":
        joinForm.addEventListener("submit", () => {
          dataLayer.push({'event': 'yourCoverStep1'});
        });
        break;
      case "your-cover-transfer":
        
        if(window.Westfund.IsOverseas){
          joinForm.addEventListener("submit", () => {
            dataLayer.push({
              'event': 'aboutYouStep4',
              'flow_section': 'About You',
              'step_number': 'Step 4',
              'visitorType': getVisitorType()
            });
          });
        }
        else{
          joinForm.addEventListener("submit", () => {
            dataLayer.push({
              'event': 'yourCoverStep2',
              'currentFund': getSelectOptionText('PreviousFundId'),
              'whyWestfund': getSelectOptionText('JoinReasonId'),
            });
          });
        }
      
        break;
      case "your-cover-lhc":
        joinForm.addEventListener("submit", () => {
          dataLayer.push({'event': 'yourCoverStep3'});
        });
        break;
      case "your-cover-concession":
        joinForm.addEventListener("submit", () => {
          dataLayer.push({'event': 'yourCoverStep4'});
        });
        break;
      case "pay-promo":
        joinForm.addEventListener("submit", () => {
          const formData = new FormData(joinForm);
          const promoCode = formData.get("PromotionCodeId") || "";
  
          dataLayer.push({
            'event': 'paySubmitStep1',
            'promoCode': promoCode,
            'visitorType': getVisitorType(),
            'flow_section': 'Pay & Submit',
            'step_number': 'Step 1'
          })
        });
        break;
      case "pay-start":
        joinForm.addEventListener("submit", () => {
          dataLayer.push({
            'event': 'paySubmitStep2',
            'visitorType': getVisitorType(),
            'flow_section': 'Pay & Submit',
            'step_number': 'Step 2'
          });
        });
      break;  
      case "pay-terms":
        joinForm.addEventListener("submit", () => {
          dataLayer.push({
            'event': 'paySubmitStep3',
            'visitorType': getVisitorType(),
            'flow_section': 'Pay & Submit',
            'step_number': 'Step 3'
          });
        });
        break;
      case "pay-details":

        // Tracking is triggered on the submitted step on page load
        
        break;  
      default:
        const memberIdMatches = window.location.href.match(/MemberId=([^&]*)/i);
        const isCorporate = window.location.href.match(/IsCorporate=1/i) !== null;
        if (memberIdMatches) {
          dataLayer.push({
            'membershipNumber': memberIdMatches[1],
            'memberType': (isCorporate)
              ? 'corporate'
              : 'consumer',
          });
        }
        break;
    }
  }

});


const hospitalSelectors = [
  ...document.querySelectorAll("button[data-select-hospital]"),
];

if (hospitalSelectors.length > 0) {
  hospitalSelectors.forEach((hospitalSelector) => {
    // Analytics spreadsheet row no. 6
    hospitalSelector.addEventListener("click", () => {

      if(window.Westfund.IsOverseas){
        const overseasHospitalCoverLevel = hospitalSelector.getAttribute("data-hospital-cover-level");
        const overseasExtrasCoverLevel = hospitalSelector.getAttribute("data-extras-cover-level");
        dataLayer.push({
          event: "extrasCoverSelection",
          hospitalCoverLevel: overseasHospitalCoverLevel,        
          extrasCoverLevel:   overseasExtrasCoverLevel,
          visitorType: getVisitorType() 
        });
      }
      else{
        dataLayer.push({
          event: "hospitalCoverSelection",
          hospitalCover: hospitalSelector.getAttribute("data-hospital-cover"),
          hospitalCoverLevel: hospitalSelector.getAttribute(
            "data-hospital-cover-level"
          ),
          excessChosen: "$" + hospitalSelector.getAttribute("data-excess-chosen"),
          visitorType: getVisitorType() 
        });
      }
      
    });
  });
}

const extrasSelectors = [...document.querySelectorAll("button[data-select-extras]")];

if (extrasSelectors.length > 0) {
  extrasSelectors.forEach((extrasSelector) => {
    // Analytics spreadsheet row no. 7
    extrasSelector.addEventListener("click", () => {
      dataLayer.push({
        event: "extrasCoverSelection",
        extrasCover: extrasSelector.getAttribute("data-extras-cover"),
        extrasCoverLevel: extrasSelector.getAttribute(
          "data-extras-cover-level"
        ),
      });
    });
  });
}

const emailQuoteForm = document.querySelector("#save-quote-modal form");

if (emailQuoteForm !== null) {
  // Analytics spreadsheet row no. 9
  emailQuoteForm.addEventListener("submit", () => {
    dataLayer.push({ 
      event: "quoteEmailed",
      visitorType: getVisitorType() 
    });
  });
}

const joinBtn = document.querySelector("button[data-join]");

if (joinBtn !== null) {
  // Analytics spreadsheet row no. 8
  joinBtn.addEventListener("click", () => {
    dataLayer.push({
      event: "joinStep1",
      coverName: joinBtn.getAttribute("data-cover-name"),
      premium: "$" + joinBtn.getAttribute("data-premium"),
      paymentFrequency: joinBtn.getAttribute("data-frequency"),
      visitorType: getVisitorType()
    });
  });
}

const formulateWrappers = [...document.querySelectorAll(".formulate-wrapper")];

if (formulateWrappers.length > 0) {
  formulateWrappers.forEach((wrapper) => {
    // Analytics spreadsheet row no. 10
    wrapper.addEventListener("formulate form: submit: success", (e) => {
      const form = e.target as HTMLFormElement;
      const appointmentTypeSelect = form.querySelector<HTMLSelectElement>(
        'select[aria-label="Appointment Type"]'
      );

      if (appointmentTypeSelect !== null) {
        const appointmentType = appointmentTypeSelect.value;
        dataLayer.push({ event: "appointmentRequested", appointmentType });
      }
    });
  });
}

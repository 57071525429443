const mobileNav = document.getElementById('mobile-menu');

if (mobileNav !== null) {
  const mobileNavBtn = document.getElementById('menu-btn');
  const mobileMediaQuery = window.matchMedia('(max-width: 768px)');
  const headerLogo = document.getElementById('header-logo');
  const corpLogo = document.getElementById('corporate-logo');
  const searchForm = document.getElementById('search-form');
  const search = document.getElementById('search') as HTMLInputElement;

  const setMenuOpen = (isOpen = true) => {
    mobileNavBtn.setAttribute('aria-expanded', isOpen ? 'true' : 'false');
    mobileNav.hidden = !isOpen;
    headerLogo.hidden = !mobileNav.hidden && mobileMediaQuery.matches;
    if(corpLogo) {
        corpLogo.hidden = !mobileNav.hidden && mobileMediaQuery.matches;
    }
    searchForm.hidden = mobileNav.hidden && mobileMediaQuery.matches;
    search.placeholder = mobileMediaQuery.matches ? 'Search Westfund' : 'Search';
  };

  mobileNavBtn.addEventListener('click', () => {
    setMenuOpen(mobileNav.hidden);
    document.body.classList.toggle('overflow-hidden');
  });

  setMenuOpen(!mobileMediaQuery.matches);

  try {
    mobileMediaQuery.addEventListener('change', (e) => setMenuOpen(!e.matches));
  } catch (e) {
    // Safari doesn't understand mobileMediaQuery.addEventListener
    mobileMediaQuery.addListener((e) => setMenuOpen(!e.matches));
  }
}

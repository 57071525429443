import "./mobileNav";

if (document.getElementById("new-product-table") !== null) {
  import("./productListings").then((script) => script.render());
}

if (document.getElementById("map") !== null) {
  import("./locationSearchMap").then((script) => script.render());
}

if (document.querySelector(".umbraco-forms-form") !== null) {
  import("./forms").then((script) => script.render());
}

if (document.querySelector("[data-tabs]") !== null) {
  import("./tabs").then((script) => script.render());
}

if (document.querySelector(".modal") !== null) {
  import("./modals").then((script) => script.render());
}

if (document.querySelector(".in-page-links") != null) {
  import("./inPageLinks").then((script) => script.render());
}

if (document.querySelector(".loading-button, .onunload-loading-button") != null) {
  import("./loadingSpinners").then((script) => script.render());
}

import "./analytics";

if (document.getElementById("chatTemplate") !== null) {
  const localOrIpAddress = new RegExp(/localhost|[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}|::1|\.local|^$/gi);
  const isLocalOrIpAddress = localOrIpAddress.test(window.location.hostname);

  if (window.location.hostname !== "" && !isLocalOrIpAddress) {
    import("./webChat").then((script) => {
      script.render();
      // script.open();
    });
  }
}

if (document.getElementById("globalDisplayBanner") !== null) {
  var globalDisplayBanner = (<HTMLElement>document.getElementById("globalDisplayBanner"));
  document.getElementById("globalDisplayBannerAction").addEventListener("click", function() {
    globalDisplayBanner.style.display = "none";
    sessionStorage.setItem("closedGlobalDisplayBanner", "true");
  });
  if (!sessionStorage.getItem("closedGlobalDisplayBanner")) {
    globalDisplayBanner.style.display = "block";
  }
}

//Accordion
// Fetch all the details element.
const details = document.querySelectorAll("details");

// Add the onclick listeners.
details.forEach((detail) => {
  detail.addEventListener("toggle", () => {
    if (detail.open) setTargetDetail(detail);
  });
});

// Close all the details that are not targetDetail.
function setTargetDetail(targetDetail) {
  details.forEach((detail) => {
    if (detail !== targetDetail) {
      detail.open = false;
    }
  });
}

